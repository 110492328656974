import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet de forma molt variable, generalment imbricat, projectat, resupinat, que pot tenir 20 cm o més i que, normalment, apareix en grups escalonats. La part superior del carpòfor és tomentosa, zonada, inicialment blanquinosa i, amb el temps, més grisa i un poc lleonada. El marge primer és blanc i estèril, després negrós. Els tubs de davall el carpòfor són molt curts, grisos i amb el temps tornen de color fosc i té uns porus, de 4-6 per mm, grisos, rodons o un poc angulosos. Les espores són de color groc palla en massa, oblongo-el·lipsoïdals, llises, de 4-6 x 2,5-3 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      